import { RoomConfiguration } from '@AuroraTypes';

const INFANT_AGE = 1;

export const getNumberOfAdultPassengers = (rooms: readonly RoomConfiguration[]) =>
  rooms.reduce((sum, room) => sum + room.adults, 0);

/**
 * Number of children in the holiday party (inclusive of infants)
 */
export const getNumberOfChildPassengers = (rooms: readonly RoomConfiguration[]) =>
  rooms.reduce((sum, room) => sum + room.childAges.length, 0);

export const getNumberOfInfantsInRoom = (room: RoomConfiguration) =>
  (room.childAges || []).reduce((sum, childAge) => {
    if (childAge <= INFANT_AGE) {
      return sum + 1;
    }

    return sum;
  }, 0);

export const getNumberOfChildrenExcludingInfantsInRoom = (room: RoomConfiguration) => {
  const numInfants = getNumberOfInfantsInRoom(room);

  return room.childAges.length - numInfants;
};

/**
 * Number of infants in the holiday party.
 * infant is a child aged 0 or 1 years of age.
 */
export const getNumberOfInfantPassengers = (rooms: readonly RoomConfiguration[]) => {
  let count = 0;
  for (const room of rooms) {
    for (const childAge of room.childAges) {
      if (childAge <= INFANT_AGE) {
        count += 1;
      }
    }
  }

  return count;
};

export const getNumberOfChargeablePassengers = (rooms: readonly RoomConfiguration[]) =>
  getNumberOfAdultPassengers(rooms) +
  getNumberOfChildPassengers(rooms) -
  getNumberOfInfantPassengers(rooms);
